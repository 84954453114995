.assistants-page-container {
  padding-top: 24px;
}

.filter-on-search-input {
  margin-left: 20px;
  cursor: pointer;
}

.message-window-style {
  width: 47px;
  height: 47px;
  border: 1px solid #dfe3e7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  cursor: pointer;
  margin-left: 20px;
}

.search-and-filter-container {
  margin-left: 16px;
}
