.logout-layout {
  padding: 12px;
  width: 202px;
  margin-left: 33px;
  margin-top: 3px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 7px #ddddde;
  position: absolute;
  z-index: 101;
  margin-top: 4px;
  margin-left: 30px;
  padding-left: 18px;
}

.edit-page {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
}
