.input_label {
  font-size: 16px;
}
.input_component {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  /* border: 1.5px solid #93c7ff; */
  /* background: white; */
  /* display: inline-block; */
  margin-top: 7px;
  font-size: 15px;
  /* margin-bottom: 19px; */
  padding: 16px;
  line-height: 18px;
}
.form_eye_div {
  top: 21px;
  right: 15px;
  position: absolute;
  cursor: pointer;
}

input:focus {
  border: 0 !important;
  box-shadow: 0 0 0 2.5px #93c7ff !important;
  -webkit-box-shadow: 0 0 0 2.5px #93c7ff !important;
  -moz-box-shadow: 0 0 0 2.5px #93c7ff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* font-size: 1.1vw !important;
    line-height: 1.3vw !important; */
}

input[data-autocompleted] {
  font-size: 1.1vw !important;
  line-height: 1.3vw !important;
}

.form_eye_container {
  position: relative;
  width: 20px;
  height: 20px;
}
p.form_eye_tooltip {
  color: #919396;
  position: absolute;
  font-family: "Mardoto", sans-serif;
  font-size: 14px;
  right: 0;
  top: 100%;
  white-space: nowrap;
  background-color: #ffffff;
  padding: 12px 20px;
  border-radius: 10px;
  display: none;
  box-shadow: 0 3px 6px 1px #babbbe;
}

.form_eye_container:hover p.form_eye_tooltip {
  display: unset;
}

@media screen and (max-width: 700px) and (orientation: landscape) {
  .input_label-mobile {
    font-size: 2vh !important;
    /* line-height: 0.9vh !important; */
    color: #4c4c4c;
  }

  .input_component-mobile {
    font-size: 2.6vh !important;
    line-height: 2.6vh !important;
    width: 100% !important;
    height: 50px !important;
  }
}

@media screen and (max-width: 700px) and (orientation: portrait) {
  .input_label-mobile {
    /* font-size: 5vw !important;
    line-height: 5.9vw !important; */
    font-size: 3vw !important;
    /* line-height: 0.9vw !important; */
    color: #4c4c4c;
  }

  .input_component-mobile {
    font-size: 3vw !important;
    line-height: 1.6vw !important;
    width: 100% !important;
    height: fit-content !important;
  }
}

@media screen and (max-height: 500px) and (max-width: 700px) {
  .input_label-mobile {
    /* font-size: 5vw !important;
    line-height: 5.9vw !important; */
    font-size: 16px !important;
    line-height: 19px !important;
    color: #4c4c4c;
  }

  .input_component-mobile {
    font-size: 15px !important;
    line-height: 18px !important;
    width: 100% !important;
    height: 45px !important;
  }
}

@media screen and (min-width: 701px) {
  .input_label-mobile {
    font-size: 1.1vw;
    line-height: 1.3vw;
  }
  .input_component-mobile {
    /*width: 23.8vw !important;*/
    margin-top: 0.4vw !important;
    font-size: 1.1vw !important;
    line-height: 1.25vw !important;
    height: 2.4vw !important;
  }
}

@media screen and (min-width: 1700px) {
  .input_component-mobile {
    width: 23.8vw !important;
  }
}

@media screen and (min-width: 1121px) and (max-width: 1340px) {
  .input_label {
    font-size: 1.1vw;
    line-height: 1.1vw;
  }
  .input_component {
    font-size: 1vw !important;
    line-height: 1.1vw !important;
    /*height: 45px !important;*/
  }
  .change-password {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 1120px) {
  .mobile_fields_focus:focus {
    border: 1px solid #93c7ff !important;
    outline: 2px solid #93c7ff !important;
    box-shadow: none !important;
  }
}
