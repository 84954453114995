.popup__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.25);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup{
    width: 360px;
    height: fit-content;
    background-color: #FFFFFF;
    box-shadow: 0 4px 7px #DDDDDE;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
}
.popup__container{
    position: relative;
}
.popup__title{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #EA6670;
    margin-bottom: 20px;
    text-align: center;
}
.popup__message{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: rgba(5, 5, 5, 0.5);
    text-align: center;
}
.popup__close{
    width: 19px;
    height: 19px;
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background-color: unset;
    transition: all .4s;
}
.popup__close:hover{
    background-color: rgba(128, 128, 128, 0.25);
}
.popup__close__icon{
    width: 100%;
    height: 100%;
}
