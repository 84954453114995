.left_sidebar_subject_container{
    width: 100%;
    max-width: 240px;
    height: 100%;
    overflow-y: auto;
}

.left_sidebar_subject_container::-webkit-scrollbar {
    width: 6px;
}

.left_sidebar_subject_container::-webkit-scrollbar-thumb {
    background: rgba(201, 201, 201, 0.55);
    border-radius: 7px;
    transition: all .4s;
}

.left_sidebar_subject_container::-webkit-scrollbar-thumb:hover {
    background: rgba(176, 176, 176, 0.55);
}
