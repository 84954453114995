.circular_loading_container {
    display:flex;
    justify-content: center;
}

.rotate_loading {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation: rotateLoader 1s infinite;
    animation-timing-function: linear;

}

@keyframes rotateLoader {
    from { rotate: 0deg }
    to { rotate: 360deg }
}
