* {
  margin: 0;
  padding: 0;
}

.show-lesson-plan-container {
  max-width: 1143px;
  padding: 25px 0;
  margin: 0 auto;
  background: #ffffff;
}

@media only screen and (max-width: 1120px) {
  .show-lesson-plan-container {
    padding: 0;
  }
}

@page {
  size: A4; /* This sets the page size to A4 */
}
