/* .added-lesson-process-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
} */

.add-for-saved-teacher-process {
  width: 148px;
  height: 54px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 9px;
}
.lesson-process-time-line-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  /* margin: 20px 0; */
}
.lesson-process-add-between-container {
  /* position: absolute;
  left: 0;
  bottom: -14px; */
}


.duration-container {
  min-width: 25px;
  height: 25px;
  background: #f7f8fc;
  border: 1px solid #dfe3e7;
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  /* margin-left: 23px; */
  /* margin-right: 23px; */
  position: absolute;
  margin-top: -8px;
  z-index: 1;
  color: #8c8e92;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding-top: 3px;
}

.selectInPopUp {
  width: 85%;
  margin: 10px 0;
}

.selectInPopUp .newMethod  {
  width: 100% !important;
  margin-left: 10px;
  font-size: 12px !important;
}

.my_select_style {
  top: 48px !important;
  right: -8px !important;
  position: absolute;
  cursor: pointer;
}