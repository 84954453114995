.POPUP_STYLES {
    position: fixed;
    top: 40%;
    left: 50%;
    height: 30%;
    width: 30%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    z-index: 1000;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 182px;
    min-width: 250px;
}
.OVERLY_STYLES {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.CLOSE_POPUP {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.BUTTON {
    margin: 10px 0;
    margin-right: 0;
}
.CHANGE_LESSON_PROCESS_BTN {
    height: 40px !important;
    width: 100% !important;
    float: right;
    background: linear-gradient(83.13deg, #6fd89c 0%, #46b776 100%);
    font-size: 14px !important;
    color: #fff;
}
.CHANGE_LESSON_PROCESS_DISABLE_BTN {
    height: 40px !important;
    width: 100% !important;
    float: right;
    cursor: not-allowed !important;
    pointer-events: none !important;
    background: #cccccc;
    color: #666666;
    font-size: 14px !important;
}


@media screen and (max-width: 700px) {
    .CLOSE_POPUP {
        right: 10px;
        top: 10px;
    }
}
@media screen and (max-height: 700px) {
    .CLOSE_POPUP {
        right: 10px;
        top: 10px;
    }
}