.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
    margin-bottom: 14px;
}

.switch input {
    display: none;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(120, 120, 128, 0.16);
    transition: all .4s;
}

.slider_div {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 2px;
    bottom: 3px;
    -webkit-transition: .4s;
    background-color: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
    transition: all .4s;
}

.switch.switch_checked .slider {
    background: linear-gradient(86.71deg, #6AB1FE -1.37%, #2B89EF 100%);
}

.switch.switch_checked .slider_div {
    left: calc(50% - 2px);
}

.slider.round {
    border-radius: 34px;
}

.slider .slider_div {
    border-radius: 50%;
}