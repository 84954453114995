/* .loading-circle-container {
  width: 35px;
  height: 35px;
} */

.text-1-and-2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  position: absolute;
  top: 33%;
  right: 0;
  left: 0;
  color: #6ab1fe;
}

.progress_bar_container {
  position: relative;
  top: 0px;
  right: 0px;
}

.progress_bar{
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 750px){
  .progress_bar{
    width: 45px;
  }
}

@media only screen and (max-width: 525px){
  .progress_bar{
    width: 40px;
  }
}
