.log-out-container-for-mobile {
  background-color: #fff;
  padding: 3px;
  position: absolute;
  right: 30px;
  top: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  /* overflow: hidden; */
}

.log-out-container-for-mobile .header-mobile-log-out {
  position: initial;
  filter: none;
  border-radius: 0;
}

.log-out-container-for-mobile-languages .languagePopUpContainer {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  left: 5px;
  bottom: -100px;
}
