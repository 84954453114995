.form_subtitle {
  text-align: center;
  margin: 30px 0;
  font-size: 20px;
}

.register_title {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #000000;
}

.register_school {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #1c1c1c;
}

.register_form {
  position: absolute;
  left: 60%;
  top: 95px;
}

.reg_page {
  text-align: center;
  margin: 30px 0 0 0;
  color: #6ab1fe;
}

.upload_container {
  display: flex;
  justify-content: center;
}

.uploaded_image {
  width: 85px;
  height: 121px;
  object-fit: cover;
}

.image_upload_title {
  text-align: center;
  margin: 8px 0;
  font-size: 12px;
}

.phone_select {
  width: 100px !important;
}

.phone_input {
  width: 235px;
  margin-left: 10px;
}

.line_div {
  display: flex;
  margin: 5px 0 0 0;
}

.checkBar {
  margin: 5px 5px 0 0;
}

.red {
  background-color: rgb(187, 11, 11);
  width: 15%;
  height: 3px;
}

.yellow {
  background-color: rgb(248, 248, 34);
  width: 30%;
  height: 3px;
}

.orange {
  background-color: orange;
  width: 45%;
  height: 3px;
}

.green {
  background-color: #3cc894;
  width: 60%;
  height: 3px;
}

.img-delete {
  background: #ffffff;
  border: 2px solid #f7f8fc;
  box-sizing: border-box;
  color: red;
  width: 28px;
  height: 28px;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  margin-left: 142px;
  position: absolute;
  margin-top: -6px;
}

.select-width {
  width: 344px !important;
}

.register-current-page {
  font-size: 20px;
  vertical-align: text-bottom;
}

.padding-div-of-image {
  cursor: pointer;
  padding: 35px 8px 9px 48px;
}

.bottom-text-register-web {
  display: flex;
  justify-content: center;
}

.bottom-text-register-mobile {
  display: none;
}

.subject_wrapper {
  position: relative;
}

.subject_class_delete {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  right: 18px;
  cursor: pointer;
  object-fit: cover;
}

.register_add_subject {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 15px 0 0;
  text-align: right;
  cursor: pointer;
}

.other-word {
  color: #6c6c6c !important;
  font-family: Mardoto, sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.15px !important;
  caret-color: transparent;
}

.option_container .other-word:hover {
  background: #fff;
  cursor: default;
}

.other-underline {
  border-bottom: 1px solid #dfe3e7;
  margin-top: 5px;
}

@media screen and (max-width: 700px) {
  .bottom-text-register-web {
    display: none;
  }

  .register_form {
    margin-left: auto;
    margin-right: auto;
    position: unset;
    margin-top: 30.8vh;
    padding: 0 4.75vw 0 4.75vw;
  }

  .select-width {
    width: 100% !important;
  }

  .register_form-mobile {
    margin-top: 30vw !important;
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: space-between;
  }

  .form_title-mobile-register {
    line-height: 6.8vw !important;
    font-size: 5.8vw !important;
  }

  .form_subtitle {
    margin: 5.1vw 0 7.5vw 0;
    font-size: 4.5vw;
    line-height: 5.9vw;
  }

  .select-label-for-mobile {
    font-size: 4vw !important;
    line-height: 5.9vw !important;
  }

  .select-input-for-mobile {
    font-size: 4vw !important;
    line-height: 5.9vw !important;
    height: fit-content;
    padding: 11px 16px;
  }

  .multi-select-input-for-mobile {
    font-size: 4vw !important;
    line-height: 5.9vw !important;
  }

  .select_container_mobile {
    position: relative;
  }

  .multiselect-with-check-input-mobile {
    height: fit-content !important;
  }
  .form_eye_div {
    top: 8px;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }

  .multiselect-with-check-arrow {
    top: 0;
    bottom: 0;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .multiselect-with-multiselect {
    width: 100%;
  }

  .bottom-text-register-mobile {
    margin-bottom: 7vw;
    font-size: 4vw;
    line-height: 5.1vw;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .select_container {
    margin: 5.25vw 0 0 0;
  }

  .register-current-page {
    font-size: 5vw;
  }

  .register-pages {
    font-size: 3.5vw;
  }

  .image_upload_title-mobile {
    font-size: 4.5vw;
    line-height: 5.9vw;
    margin: 5.9vw 0;
  }

  .image_upload_size-mobile {
    font-size: 3.75vw !important;
    line-height: 5.65vw;
    margin: 5vw 0;
  }

  .custom-img-upload {
    width: 26.5vw;
    line-height: 12.5vw;
    height: 37.8vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .padding-div-of-image {
    padding: 8vw 0 0 0;
  }

  .phone_select {
    width: 28vw !important;
  }

  .input_component {
    width: 61vw;
  }

  .select-cont-phone {
    margin: 0 !important;
  }

  .phone-content {
    margin: 20px 0 0 0;
  }

  .password-register-page-height {
    height: 80vh !important;
  }

  .phone-label-mobile {
    font-size: 4vw !important;
    line-height: 5.9vw !important;
  }

  .green {
    width: 90%;
  }

  .orange {
    width: 55%;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    height: fit-content;
    top: 0;
    z-index: 1;
  }

  .mt-20 {
    height: fit-content !important;

    font-size: 4vw !important;
    line-height: 5.9vw !important;
    padding: 10px 0 10px 16px;
  }

  .forgotSuccess {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 10vw;
    font-size: 5vw;
    text-align: center;
  }

  .subjects_container > img {
    right: 0px !important;
    top: -5px !important;
  }
}

@media screen and (min-width: 701px) {
  .register_form {
    position: absolute;
    display: flex;
    top: 9.4vw;
    left: 50%;
    width: 50%;
    justify-content: center;
  }

  .bottom-text-register-web {
    display: none;
  }

  .select-width {
    width: 100% !important;
  }

  .register_form-mobile {
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: space-between;
    padding: 0 13.1vw;
  }

  .form_title-mobile-register {
    line-height: 2.43vw !important;
    font-size: 2vw !important;
  }

  .form_subtitle {
    margin: 3vw 0 3vw 0;
    font-size: 1.5vw;
    line-height: 1vw;
  }

  .select-label-for-mobile {
    font-size: 1.1vw !important;
    line-height: 1.3vw !important;
  }

  .select-input-for-mobile {
    font-size: 2vw !important;
    line-height: 3vw !important;
    /*height: fit-content;*/
    padding: 11px 16px;
  }

  .multi-select-input-for-mobile {
    font-size: 2vw !important;
    line-height: 3vw !important;
  }

  .select_container_mobile {
    position: relative;
  }

  .multiselect-with-check-input-mobile {
    height: fit-content;
  }

  .bottom-text-register-mobile {
    margin-bottom: 7vw;
    font-size: 4vw;
    line-height: 5.1vw;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .register-current-page {
    font-size: 2vw;
  }

  .register-pages {
    font-size: 1.5vw;
  }

  .image_upload_title-mobile {
    font-size: 1.5vw;
    line-height: 1vw;
    margin: 2vw 0;
  }

  .image_upload_size-mobile {
    font-size: 1vw !important;
    line-height: 5.65vw;
    margin: 1vw 0;
  }

  .custom-img-upload-register {
    width: 9vw !important;
    line-height: 8.4vw !important;
    height: 12vw !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .padding-div-of-image {
    padding: 4vw 0 0 0;
  }

  .phone_select {
    width: 9vw !important;
  }

  /* .input_component {
    width: 61vw;
    } */
  .phone_input {
    width: 14vw !important;
  }

  .select-cont-phone {
    margin: 0 !important;
  }

  .phone-content {
    margin: 20px 0 0 0;
  }

  .password-register-page-height {
    height: 80vh !important;
  }

  .phone-label-mobile {
    font-size: 1.1vw !important;
    line-height: 1.3vw !important;
  }

  .green {
    width: 90%;
  }

  .orange {
    width: 55%;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    height: fit-content;
    top: 0;
    z-index: 1;
  }

  .mt-20 {
    width: 100%;
    justify-content: center;
    height: fit-content;
  }

  .container {
    position: absolute;
  }

  .bottom-text-register-mobile {
    font-size: 1.1vw;
    line-height: 1.6vw;
  }

  .select-input-for-mobile,
  .multi-select-input-for-mobile {
    font-size: 1.1vw !important;
    line-height: 1.3vw !important;
  }

  .line_div {
    margin: 0.2vw 0 0 0;
    align-items: center;
  }

  .phone-input-div-reg {
    margin: 0 !important;
    right: 15px;
  }
}
