.lesson_plan_sidebar {
  background-color: #f7f8fc;
  width: 26%;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 89px;
  z-index: 1;
  transition: all 0.4s;
}
.lesson_plan_sidebar_nav {
  padding-left: 9px;
  padding-top: 25px;
  position: relative;
}

.lesson_plan_sidebar_item_container {
  display: flex;
}

.lesson_plan_sidebar_item_clear_btn {
  border-radius: 5px;
  background-color: transparent;
  padding: 0 10px;
}

.lesson_plan_sidebar_item_clear_btn:hover {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

.lesson_plan_sidebar_open {
  position: absolute;
  top: 0;
  right: -35px;
  width: 35px;
  height: 35px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f7f8fc;
  padding: 5px;
  border-radius: 10px;
}
.lesson_plan_sidebar_open_icon {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}

.lesson_plan_sidebar_open_icon.open {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 750px) {
  div.lesson_plan_sidebar {
    width: 250px !important;
    left: -250px;
  }

  div.lesson_plan_sidebar.open {
    left: 0;
  }
  .lesson_plan_sidebar_open {
    display: flex;
  }
}
