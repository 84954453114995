.image_uploader_wrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: linear-gradient(88.37deg, rgba(83, 102, 162, 0.25) 1.28%, rgba(40, 60, 121, 0.25) 100%);
    cursor: auto;
}

.image_uploader_container{
    width: 100%;
    max-width: 876px;
    border-radius: 12px;
    background: #FFFFFF;
    padding: 60px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 355px;
}

.image_uploader_close{
    width: 12px;
    height: 12px;
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
}

.image_uploader_close_icon{
    width: 100%;
    height: 100%;
    margin: 0;
}

.image_uploader_body{
    width: 100%;
    max-width: 576px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image_uploader_area{
    width: 100%;
    padding: 16px 24px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    transition: all .4s;
    position: relative;
}

.image_uploader_area.drag{
    background-color: #fff9f9;
    border: 1px dashed rgba(0, 0, 0, 0.49);
}

.image_uploader_icon{
    width: 48px;
    height: 48px;
    padding: 6px 8px;
    margin-right: 16px;
}

.image_uploader_info{
    width: 287px;
}

.image_uploader_info_title{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 12px;
}

.image_uploader_info_desc{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.4);
}

.image_uploader_select_btn{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: white;
    opacity: 1;
    border-radius: 6px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #46B776;
}

.image_uploader_file{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.image_uploader_file_info{
    display: flex;
    align-items: center;
    padding: 17px 19px ;
    width: 100%;
}

.image_uploader_file_icon{
    width: 24px;
    height: 24px;
}

.image_uploader_input{
    display: none;
}

.image_uploader_file_name{
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    width: 100%;
    margin-left: 16px;
}

.image_uploader_file_size{
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: rgba(0, 0, 0, 0.7);
}

.image_uploader_file_delete{
    width: 24px;
    height: 24px;
    margin-left: 28px;
    cursor: pointer;
    transition: all .4s;
}

.image_uploader_file_delete_icon{
    width: 100%;
    height: 100%;
    margin: 0;
}

.image_uploader_actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.image_uploader_cancel{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #8C8E92;
    background: #EEF1F6;
    border-radius: 6px;
    padding: 11px 15px;
    margin-right: 12px;
}

.image_uploader_save{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: linear-gradient(83.13deg, #6FD89C 0%, #46B776 100%);
    border-radius: 6px;
    padding: 11px 15px;
}

.image_uploader_card{
    width: 240px;
    height: 100%;
    border-radius: 12px;
    border: 0;
    min-height: 275px;
}

.image_uploader_card_image_figure{
    border-radius: 12px 12px 0 0;
    width: 240px;
    height: 120px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image_uploader_card_image_edit{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.20);
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
}

.image_uploader_card_image_figure:hover .image_uploader_card_image_edit {
    display: flex;
}

.image_uploader_card_image{
    filter: none;
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;

}

.image_uploader_card_body{
    position: relative;
    width: 100%;
}

.image_uploader_card_profile_image_figure{
    width: 60px;
    height: 60px;
    position: absolute;
    top: -30px;
    left: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.image_uploader_card_profile_image{
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
}

.image_uploader_card_name{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4A4A4A;
    margin-bottom: 30px;
    margin-left: 80px;
    width: 150px;
    word-wrap: break-word;
}

.image_uploader_card_title{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1C1C1C;
    padding: 0 25px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
