.green_button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Mardoto', sans-serif;
    color: #FFFFFF;
    background: linear-gradient(83.13deg, #6FD89C 0%, #46B776 100%);
    border-radius: 6px;
    padding: 11px 15px;
}

.green_button:active {
    background: linear-gradient(83.13deg, #5de396 0%, #35a664 100%);
}
