.app1 {
  font-family: sans-serif;
  text-align: center;
}

/* html,
body {
  overflow: hidden;
} */
.overflow-hidden {
  overflow: hidden;
}
article {
  max-width: 600px;
  border: 1px solid blue;
 
}

.highlight.coral {
  background-color: coral;
}

.highlight.aqua {
  background-color: aqua;
}

.minimap-container {
  height: 100% !important;
  display: flex ;
  justify-content: center ;
  position: absolute !important;
}

.minimap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  background-color: white;
  border: 1px solid purple;
  font-size: 2.8px !important;
height: fit-content !important;
  width: 130px !important;
 
}



.minimap-viewport {
  opacity: 0.35;
  width: 130px !important;
  height: 24vh !important;
}

.minimap-children {
  background-color: pink;
  border: none;
  opacity: 0.4;
}

.minimap-children:hover {
  opacity: 1;
  cursor: pointer;
}