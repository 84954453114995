.container {
    margin: 25px 20px 20px 18px;
    width: 322px;
}

.title {
    font-family: 'Mardoto';
    color: #1C1C1C;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.container .infoContainer img {
    max-width: 322px;
}
