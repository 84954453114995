.lesson-plan-print-files-pop-up {
  width: 331px;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.print-files-pop-up-header {
  font-family: 'Mardoto', sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 16px 26px 16px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1c1c1c;
  border-bottom: 1px solid #dfe3e7;
}
.print-files-pop-up-body {
  margin-left: 4px;
  overflow-y: scroll;
  max-height: 382px;
  margin-right: 2px;
}

.print-files-pop-up-body::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.print-files-pop-up-body::-webkit-scrollbar-track {
  /* background: #bd3d3d; */
}

/* Handle */
.print-files-pop-up-body::-webkit-scrollbar-thumb {
  background: #dfe3e7;
  border-radius: 7px;
}

/* Handle on hover */
.print-files-pop-up-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 1120px) {
  .lesson-plan-print-files-pop-up {
    top: 0;
    left: 0;
  }
}
