.lsn_plan_view_toggle{
    width: 48px;
    height: 48px;
    border: 1px solid #DFE3E7;
    border-radius: 12px;
    background-color: #fff;
    padding: 16px 14px;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lsn_plan_view_toggle_icon{
    width: 20px;
    height: 16px;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 1295px){
    .lsn_plan_view_toggle{
        width: 40px;
        height: 40px;
    }
}