.table-content {
  border-collapse: separate;
  border-spacing: 0 5px;
  border-radius: 10%;
}
.table-content tr {
  display: table-row;
  border: 1px solid black !important;
  border-radius: 50% !important;
}
.table-content th,
.table-content td {
  padding: 15px;
  margin-top: 10px;
}
:root {
  --tr-border-radius: 10px;
}
.table-content td {
  border-top: 1px solid #dfe3e7;
  border-bottom: 1px solid #dfe3e7;
}
.table-content td:first-child {
  border-left: 1px solid #dfe3e7;
  border-top-left-radius: var(--tr-border-radius);
  border-bottom-left-radius: var(--tr-border-radius);
}

.table-content td:last-child {
  border-right: 1px solid #dfe3e7;
  border-top-right-radius: var(--tr-border-radius);
  border-bottom-right-radius: var(--tr-border-radius);
}

.display-flex-td {
  display: table-cell !important;
}
