.sidebarContainer {
    background-color: #FFF;
    height: calc(100vh - 90px);
    position: absolute;
    right: 0;
    transition: 0.8s;
    box-shadow: 10px 10px 20px -2px rgba(0,0,0,0.95);
    max-width: 360px;
    z-index: 100;
}
.showSidebar {
    width: 100%;
    min-width: 360px;
}
.hideSidebar {
    width: 0;
    min-width: 0;
}
.rightSidebar {
    position: relative;
}

.children {
    height: calc(100vh - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.toggleSidebar {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 25px;
    left: -37px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #FFF;
    width: 40px;
    /*height: 40px;*/
    box-shadow: -2px 0px 5px -3px rgba(0,0,0,0.95);
}

.toggleSidebar::before {
    content: url("../../img/close-part1.svg");
    position: absolute;
    top: -15px;
    right: 0px;
}

.toggleSidebar::after {
    content: url("../../img/close-part1.svg");
    position: absolute;
    transform: rotate(-90deg);
    bottom: -14px;
    right: -2px;
}
