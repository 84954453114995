.margin-top-for-table {
  margin-top: 10px;
}

.addition-table-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
  text-transform: uppercase;
  color: rgba(57, 58, 60, 0.5);
}

.addition-table-title :where(p, div) {
  cursor: pointer;
}
.table-topic {
  line-break: anywhere;
}

.right-sidebar-ellipsis {
  cursor: pointer;
}

.row-display-none .right-sidebar-ellipsis {
  width: 17px;
  height: 4px;
}

.calendar-for-table {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.calendar-date {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c8e92;

  text-transform: capitalize;
}

.options-column {
  width: 40px;
}

.views {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(57, 58, 60, 0.5);
}

.class {
  height: 14px;
  border-radius: nullpx;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(57, 58, 60, 0.5);
  width: 15%;
}

.date {
  height: 14px;
  border-radius: nullpx;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(57, 58, 60, 0.5);
  width: 15%;
}

.table-title {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /*text-transform: capitalize;*/
  color: #1c1c1c;
}

.eye {
  height: 7px;
  width: 9px;
  border-radius: 0px;
  border: 0.2px solid #e4f0fe;
  box-sizing: border-box;
}

.table_title {
  text-align: center;
}

.columns {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.heart {
  height: 20px;
  width: 20px;
  border-radius: 0;
}

.table_row {
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #dfe3e7;
  border-radius: 10px;
  margin: 0 0 5px 0;
}

.views-width {
  width: 65px;
}

.class-width-in-table {
  width: 100px;
}

.class-column-width {
  width: 100px;
}

.date-width {
  width: 115px;
}

.date-column-width {
  width: 100px;
}

.topic-column-width {
  width: 345px;
}

.topic-column-width > .table_title {
  text-align: left;
}

.table_title {
  display: flex;
  align-items: center;
}

.views-column-width {
  width: 80px;
}

.triangle-icon {
  position: relative;
  margin-left: 5px;
  bottom: 1px;
}

tr.table_row:hover {
  background: rgba(244, 246, 250, 0.85);
}

.table-row-content {
  vertical-align: middle;
  padding: 15px;
}

.numbers_views {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.class-value {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #8c8e92;
  text-align: center;
  width: 100px;
}

.some_div {
  min-width: 50px;
  width: fit-content;
  display: flex;
  height: 20px;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 6px;
  background: rgba(77, 158, 246, 0.15);
  border-radius: 4px;
  margin: 0 auto;
}

.table-view-icon {
  width: 10px;
  height: 8px;
  margin-right: 3px;
  vertical-align: middle;
}

.picture_in_table {
  height: 29px;
  width: 29px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}

.table {
  border-spacing: -1px 13px;
  min-height: 60px;
  display: grid;
}

tr {
  border: 0;
  display: block;
}

.table-ellipsis {
  vertical-align: middle;
}

.table-title-for-ellipsis {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(57, 58, 60, 0.5);
}

.edit-lsn-plan-div {
  margin-top: 10px;
}

.edit-lsn-plan-text {
  color: #2b89ef;
}

.pencil-icon {
  height: 18px;
}

.picture-column-width {
  width: 53px;
}

.topic-width {
  width: 100%;
}
.table-width .table-title {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 30px;
}

.picture-width {
  width: 43px;
}

.image-display-in-table {
  display: none !important;
}

.table-width {
  padding: 0 1vw;
  width: 100%;
  margin: 0 auto;
}

.table_toggle {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rate {
  margin-left: 30px;
}

.left {
  margin-right: 15px;
}

.rate-empty {
  width: 72px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
}

@media screen and (min-width: 100px) and (max-width: 699px) {
  .lsn-plan-thead-mobile {
    display: none;
  }

  .table-title-for-ellipsis-mobile {
    font-size: 4vw !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    padding-left: 10px;
  }

  .row-display-none {
    display: none !important;
  }

  .table-container-mobile {
    max-width: 100% !important;
  }

  .columns-mobile {
    line-height: 4.3vw !important;
    float: right;
  }

  .margin-top-for-table {
    margin-top: 0;
  }

  .table-row-content-mobile {
    display: flex;
    align-items: center;
  }

  .picture-width {
    width: 10vw;
  }

  .picture_in_table-mobile {
    width: 9vw !important;
    height: 9vw !important;
  }

  .table-width {
    width: 100%;
    padding: 0;
  }

  .image-display-in-table {
    display: block !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 1120px) {
  .lsn-plan-thead-mobile {
    display: none;
  }

  .table-title-for-ellipsis-mobile {
    font-size: 2.5vw !important;
    width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    padding-left: 0;
  }

  .row-display-none {
    display: none !important;
  }

  .table-container-mobile {
    max-width: 100% !important;
  }

  .columns-mobile {
    font-size: 12px !important;
    line-height: 4vw !important;
    float: right;
  }

  .margin-top-for-table {
    margin-top: 0;
  }

  .table-row-content-mobile {
    padding: 15px !important;
    margin: 12px 20px;
    display: flex;
    align-items: center;
  }

  .picture-width {
    width: 10%;
  }

  .picture_in_table-mobile {
    width: 7vw !important;
    height: 7vw !important;
  }

  .table-width {
    width: 100%;
    padding: 0;
  }

  .image-display-in-table {
    display: block !important;
  }
}

@media screen and (min-width: 1121px) and (max-width: 1254px) {
  .addition-table-title {
    font-size: 10px;
  }

  .table_row {
    font-size: 1.1vw;
    line-height: 1.3vw;
  }

  .columns {
    font-size: 0.8vw;
    align-items: center;
  }
  .numbers_views {
    line-height: 0.8vw;
    font-size: 0.8vw;
  }
}

@media screen and (min-width: 1255px) and (max-width: 1450px) {
  .table_row {
    font-size: 1.1vw;
    line-height: 1.3vw;
  }

  .columns {
    font-size: 0.8vw;
  }

  .columns {
    align-items: center;
  }

  .numbers_views {
    line-height: 0.8vw;
    /*font-size: 0.8vw;*/
  }

  .table-title-for-ellipsis {
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media screen and (min-width: 1121px) {
  .table-title {
    margin-left: 14px;
  }
}

@media screen and (max-width: 1500px) {
  .date-column-width {
    width: 85px;
  }
}

@media screen and (max-width: 1450px) {
  .picture-column-width {
    width: 43px;
  }
}

@media only screen and (max-width: 1355px) {
  /*.views-column-width {*/
  /*    width: 85px;*/
  /*}*/

  /*.date-column-width {*/
  /*    width: 105px;*/
  /*}*/

  /*.picture-column-width {*/
  /*    width: 60px;*/
  /*}*/
}

@media only screen and (max-width: 1290px) {
  .date-width {
    width: 120px;
  }

  .topic-column-width {
    width: 350px;
  }
}

@media only screen and (max-width: 1270px) {
  .class-column-width {
    width: 90px;
  }

  .date-width {
    width: 120px;
  }

  .options-column {
    width: 30px;
  }

  .class-column-width {
    width: 100px;
  }
}

@media screen and (max-width: 1120px) {
  .options-column {
    display: none;
  }

  .class-width-in-table {
    width: 20%;
  }

  .profile_wrapper .action-container {
    display: none;
  }

  .table-container-mobile {
    width: 100%;
  }
  .table-width .table-title {
    white-space: nowrap;
    display: inline-block;
    max-width: 200px !important;
  }

  .footer-container {
    position: relative;
    /*background-color: red;*/
    display: flex;
    justify-content: center;
    padding-top: 7px;
  }

  .footer-container .spinner_container {
    width: 50px;
    height: 50px;
    position: relative;
  }

  .footer-container .spinner {
    width: 28px;
    height: 28px;
    display: grid;
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side, #0000 40%, #9f9f9f 41%);
    background: linear-gradient(
          0deg,
          rgba(159, 159, 159, 0.5) 50%,
          rgba(159, 159, 159, 1) 0
        )
        center/1.9px 100%,
      linear-gradient(
          90deg,
          rgba(159, 159, 159, 0.25) 50%,
          rgba(159, 159, 159, 0.75) 0
        )
        center/100% 1.9px;
    background-repeat: no-repeat;
    animation: loadingSpinner 1s infinite steps(12);
  }

  .footer-container .spinner::before,
  .footer-container .spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  .footer-container .spinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }

  @keyframes loadingSpinner {
    100% {
      transform: rotate(1turn);
    }
  }
}

@media screen and (max-width: 360px) {
  .table-width .table-title {
    max-width: 100px !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 400px) {
  .table-width .table-title {
    max-width: 150px !important;
  }
}
