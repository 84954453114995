.recent-actions-container {
    margin-top: 15px;
    background: #ffffff;
    box-shadow: 0 4px 21px 7px #dbdfee4a;
    border-radius: 12px;
    padding: 0 11px 35px;
    display: flex;
    flex-direction: column;
}
.action-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #DFE3E7;
    width: 100%;
}
.action {
    padding: 15px 33px;
    height: 54px;
    cursor: pointer;
    border-radius: 12px;
    position: relative;
    background-color: #FFFFFF;
}
.action-selected-title {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: linear-gradient(86.71deg, #6AB1FE -1.37%, #2B89EF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.action-title {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #8C8E92;
}
.underline-action-container{
    width: 62px;
    background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
    box-shadow: 0 2px 4px 0 #4a9cf661;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

/*@media screen and  (min-width:100px) and (max-width:699px)  {*/
/*    .recent-actions-container {*/
/*        background: #F7F8FC;*/
/*        box-shadow: unset;*/
/*        border-radius: 0;*/
/*    }*/
/*    .action-container {*/
/*        display: flex;*/
/*        margin-top: 0;*/
/*        flex-wrap: nowrap;*/
/*        overflow-x: scroll;*/
/*        padding: 5vw 0 0 0;*/
/*        border-bottom: 1px solid;*/
/*    }*/
/*    .action {*/
/*        padding: 0 5vw 3.75vw 0.1vw;*/
/*        background-color: unset;*/
/*        height: auto;*/
/*        width: 100%;*/
/*    }*/
/*    .action-selected-title, .action-title {*/
/*        font-size: 4vw;*/
/*        line-height: 4.6vw;*/
/*    }*/
/*    .underline-profile-mobile {*/
/*        display: flex;*/
/*        justify-content: center;*/
/*    }*/
/*    .underline-action {*/
/*        width: 11.8vw;*/
/*    }*/
/*}*/

/*@media screen and  (min-width:700px) and (max-width:1120px)  {*/
/*    .recent-actions-container {*/
/*        background: #F7F8FC;*/
/*        box-shadow: unset;*/
/*        border-radius: 0;*/
/*    }*/
/*    .action-container {*/
/*        display: flex;*/
/*        margin-top: 0;*/
/*        flex-wrap: nowrap;*/
/*        overflow-x: scroll;*/
/*        padding: 2vw 0 0 0;*/
/*        border-bottom: 1px solid;*/
/*    }*/
/*    .action {*/
/*        padding: 0 3vw 2vw 0.1vw;*/
/*        background-color: unset;*/
/*        height: auto;*/
/*        width: 100%;*/
/*    }*/
/*    .action-selected-title, .action-title {*/
/*        font-size: 2.5vw;*/
/*        line-height: 3.6vw;*/
/*    }*/
/*    .underline-profile-mobile {*/
/*        display: flex;*/
/*        justify-content: center;*/
/*    }*/
/*    .underline-action {*/
/*        width: 8.8vw;*/
/*    }*/
/*}*/

/*@media screen and  (min-width:1120px) and (max-width:1450px)  {*/
/*    .action {*/
/*        padding: 1.25vw 2vw 1.1vw 2vw;*/
/*        height: auto;*/
/*    }*/
/*    .action-selected-title, .action-title  {*/
/*        font-size: 1.1vw;*/
/*        line-height: 1.3vw;*/
/*    }*/
/*    .underline-action {*/
/*        width: 4.8vw;*/
/*    }*/
/*}*/
