.tabs-container {
  margin-top: 32px;
  border-radius: 12px;
  padding: 0 11px 35px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px solid #dfe3e7;
  width: 348px;
}

.tab {
  padding: 15px 30px;
  height: 54px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #f2f2f2;
}

.tab-selected-title {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.tab-title {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8c8e92;
}

.underline-tab-container {
  width: 62px;
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  box-shadow: 0 2px 4px 0 #4a9cf661;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
}
