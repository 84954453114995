.all-links-on-lsn-plan-pdf {
    width: 444px;
    height: 484px;
    position: absolute;
    top: 60px;
    right: -10px;
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.all-links-on-lsn-plan-pdf header {
    height: 56px;
    border-bottom: 1px solid #DFE3E7;
    padding: 0 24px;
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1C1C1C;
}

.all-links-on-lsn-plan-pdf .body {
    max-height: 320px;
    overflow-y: scroll;
}

.all-links-on-lsn-plan-pdf .footer {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
}
.all-links-on-lsn-plan-pdf .footer button {
    font-family: 'Mardoto', sans-serif;
    background: linear-gradient(83.13deg, #6FD89C 0%, #46B776 100%);
    color: #fff;
    border-radius: 6px;
    height: 40px;
    width: 200px;
}



.all-links-on-lsn-plan-pdf .body::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.all-links-on-lsn-plan-pdf .body::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
.all-links-on-lsn-plan-pdf .body::-webkit-scrollbar-thumb {
    background: #B7B7B7;
    border-radius: 10px;
}

/* Handle on hover */
.all-links-on-lsn-plan-pdf .body::-webkit-scrollbar-thumb:hover {
}

@media only screen and (max-width: 1120px) {
    .all-links-on-lsn-plan-pdf {
        top: 0;
        left: 3px;
        width: 98.5dvw;
        height: calc(100dvh - 44px);
    }
    .all-links-on-lsn-plan-pdf .body {
        max-height: 81% !important;
    }
}
