.popUpContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 642px;
    width: 444px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    z-index: 1000;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    min-height: 182px;
    min-width: 250px;
    gap: 34px;
}

.popUpBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(40, 60, 121, 0.25);
    z-index: 1000;
}

.popUpContainer > h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1C1C1C;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    /*gap: 20px;*/
}
