.allImagesInAction {
    width: 210px;
    height: 162px;
    position: absolute;
    top: -30px;
    left: 120px;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 5px 3px;
}
.polygonSvg {
    position: absolute;
    left: -7px;
    top: 50px;
    filter: drop-shadow(-3px 0px 2px rgba(0, 0, 0, 0.25))
}
.headerImagesModal > span {
    font-size: 19px;
    color: red;
    position: absolute;
    right: 5px;
    top: 0px;
    padding: 0 3px;
    cursor: pointer;
}
.bodyImagesModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 148px;
    overflow: auto;
    background-color: #fff;
}
.imageInModal {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-top: 1px solid lightgrey;
}

.imageInModal:nth-child(1) {
    border-top: 0;
}


.allImagesInAction ::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.allImagesInAction ::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
.allImagesInAction ::-webkit-scrollbar-thumb {
    background: #B7B7B7;
    border-radius: 10px;
}

/* Handle on hover */
.allImagesInAction ::-webkit-scrollbar-thumb:hover {
    background: #4b7eef;
}

