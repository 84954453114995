.students-lesson-plan {
  width: 100%;
  height: 85vh;
}

.lesson-plan-header {
  width: 100%;
  background-color: #3a4e8a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 25px;
  margin-bottom: 25px;
}

.lesson-plan-header-responsive {
  width: 100%;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 16px;
  text-align: center;
  position: fixed;
  background: #fff;
  top: 0;
  z-index: 999;
}
.stm-logo {
  margin-right: 83px;
}

.lesson-plan-title {
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Mardoto", sans-serif;
  line-height: 32.81px;
}

.lesson-plan-content {
  padding: 0 45px 0 23px;
  height: 100%;
}

.lesson-plan-content-responsive {
  padding: 0 20px 72px 23px;
  height: 100%;
}

.logo-button {
  background-color: transparent;
  width: 50px;
  margin-right: 60px;
}

.logo-button-for-mobile {
  width: 120px;
  background-color: transparent;
}
