.rating-tr {
  border: 1px solid rgba(223, 227, 231, 1);
  margin-bottom: 8px;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid red;
}

.tableContainer table {
  width: 100%;
  border-collapse: separate;
  margin-left: 10px;
  caption-side: top;
  empty-cells: hide;
  table-layout: auto;
  border-spacing: 0 8px;
  /* margin-top: 65px; */
}

.rating td {
  border-top: 1px solid rgba(223, 227, 231, 1);
  border-bottom: 1px solid rgba(223, 227, 231, 1);
}

.rating td:first-child {
  border-left: 1px solid rgba(223, 227, 231, 1);
}

.rating td:nth-last-child(1) {
  border-right: 1px solid rgba(223, 227, 231, 1);
}

.views {
  text-align: center;
  background: rgba(228, 240, 254, 1);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  max-width: 42px;
}

.rating-view {
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rate {
  background-color: rgba(255, 245, 233, 1);
  width: 42px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
}

.rating-text {
  background: linear-gradient(74.09deg, #fbb86b 0%, #e38d2a 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 3px;
}

.user-image {
  width: 29px;
  height: 29px;
  border-radius: 50px;
  margin-right: 7.5px;
  object-fit: cover;
}

.user-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 164px;
}

.topic {
  color: rgba(28, 28, 28, 1) !important;
}

.topic-text {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  width: 300px;
}

.sort-icon {
  margin-left: 5px;
}

.column-title {
  text-align: center;
}

.sort {
  cursor: pointer;
  min-width: 110px;
}

.users-name {
  font-weight: 500;
  font-size: 12px;
  color: rgba(140, 142, 146, 1);
}

.users-view {
  font-weight: 400;
  font-size: 12px;
  color: rgba(140, 142, 146, 1);
}

.last-td {
  border-radius: 0 10px 10px 0;
}

.rating {
  margin-top: 20px !important;
}
