.user-activity-card-container {
    background: #FAFBFC;
    border: 1px solid #E0E3EC;
    border-radius: 12px;
    padding: 15px 17px 17px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 16px;
    position: relative;
}
.activity-message {
    font-family: 'Mardoto', sans-serif;;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    max-width: 210px;
    color: #1C1C1C;
    margin-bottom: 13px;
    line-break: anywhere;
}
.activity-date {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8C8E92;
    text-transform: capitalize;
}

.user-activity-arrow{
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 16px;
    height: 8px;
    opacity: 0;
    transition: all .4s;
}
.user-activity-arrow-icon{
    width: 100%;
    height: 100%;
}
.user-activity-card-container:hover{
    background: rgba(140, 142, 146, 0.15);
}
.user-activity-card-container:hover .user-activity-arrow{
    opacity: 1;
}
