.open_layout {
  /* position: absolute; */
  /* position: absolute; */
  z-index: 99999;
  width: 100%;
  height: 321px;
  margin-top: 12px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(33 91 162 / 21%);
  padding: 5px 31px 27px 20px;
}

.open-layout-subject-container {
  width: 346px;
}
.open-layout-arrow {
  right: 70.08px;
  position: absolute;
  top: 73px;
  margin-left: 10px;
}

.class-width {
  width: 100% !important;
}

.selects-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 758px;
}

.select-method {
  width: 346px;
  margin-top: -15px;
}

.select-method-label {
  font-size: 16px;
}

.select-method-container {
  margin-top: 15px;
}

@media screen and (min-width: 1121px) and (max-width: 1340px) {
  .select-method-label {
    font-size: 1.1vw;
  }
}
