.searchInputContainer {
    display: flex;
}

.searchLabel {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-right: 42px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* Black */
    color: #1C1C1C;
}
.searchInputDiv {
    display: flex;
    gap: 13px;
    width: 59%;

    position: relative;
}
.searchInput {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #DFE3E7;
    border-radius: 12px;
    padding: 15px 12.7px;
}
.searchInputIcon {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 17.7px;
    height: 17.71px;
    cursor: pointer;
}
.searchInput:focus {
    border: 1px solid #93c7ff !important;
}
