.popup-box {
  position: fixed;
  z-index: 101;
  background: linear-gradient(
    88.37deg,
    rgba(83, 102, 162, 0.25) 1.28%,
    rgba(40, 60, 121, 0.25) 100%
  );
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 444px;
  margin: 0 auto;
  max-height: 85vh;
  margin-top: calc(95vh - 85vh - 20px);
  padding: 25px;
  overflow: auto;
  background: #ffffff;
  border-radius: 8px;
}
.close-icon {
  content: "x";
  cursor: pointer;
  float: right;
  /* background: #ededed; */
  width: 25px;
  height: 25px;
  /* top: 10px; */
  /* margin-top: 21px; */
  /* border-radius: 50%; */
  line-height: 20px;
  text-align: center;
  /* border: 1px solid #999; */
  font-size: 20px;
}

.box .form_eye_div {
  right: 17px;
}

.box label {
  font-family: 'Mardoto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: #1C1C1C;
}