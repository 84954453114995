.submit_delete_confirm {
  font-family: "Mardoto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background-color: #ea6670;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  text-align: center;
}
