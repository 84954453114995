.comment_header {
    width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}
.comment_header .comment_header_arrows {
    display: flex;
    align-items: center;
}
.comment_header .comment_header_arrows_left {
    padding-right: 5px ;
    margin-right: 10px;
    cursor: pointer;
}
.comment_header .comment_header_arrows_right {
    padding-left: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.comment_header .comment_header_close {
    display: flex;
    align-items: center;
    padding-left:  5px;
    margin-left: 5px;
    cursor: pointer;
}
