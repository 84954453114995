.forgot_pass_title{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #000000;
}
.forgot_pass_text{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #1C1C1C;
}

.forgot_pass_formplace {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 1700px){
    .input-and-label-forgot {
        width: 343px;
    }
}

@media only screen and (max-width: 700px){
    .forgot_pass_submit_wrapper{
        width: 100%;
        margin-top: 28px;
    }
}