.personal {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 21px 7px rgba(219, 223, 238, 0.29);
    border-radius: 12px;
    padding: 13px;
    display: flex;
}

.message_window_wrapper {
    position: absolute;
    width: 100%;
    z-index: 100;
}

.personal_figure {
    width: 204px;
    height: 204px;
    margin-right: 22px;
    border-radius: 8px;
}

.personal_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin: 0;
}

.personal_details {
    width: calc(100% - 226px);
    padding-top: 9px;
}

.personal_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.personal_school {
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0 4px 21px rgba(219, 223, 238, 0.5);
    border-radius: 14px;
    padding: 8px 14px;
    display: flex;
}

.personal_school_icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.personal_school_name {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1C1C1C;
}

.personal_message {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #DFE3E7;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 7px;
}

.personal_message_icon {
    width: 35px;
    height: 25px;
}

.personal_name_type_container{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding-right: 7px;
}

.personal_name {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1C1C1C;
    margin-bottom: 5px;
    max-width: 410px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.personal_contact {
    display: flex;
    margin: 12px 0;
    flex-wrap: wrap;
}

.personal_education {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-right: 55px;
}

.personal_education_icon {
    width: 17px;
    height: 14px;
    margin-right: 11px;
}

.personal_education_text {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8C8E92;
}

.personal_email {
    width: fit-content;
    display: flex;
    align-items: center;
}

.personal_email_icon {
    width: 14px;
    height: 12px;
    margin-right: 13px;
}

.personal_email_text {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8C8E92;
}

.personal_activities {
    width: 100%;
    display: flex;
}

.personal_activity {
    width: fit-content;
    padding: 0 40px;
    position: relative;
    background: linear-gradient(86.71deg, #6AB1FE -1.37%, #2B89EF 100%);
    background-clip: text;
}

.personal_activity.purple {
    background: linear-gradient(74.09deg, #9D9AFF 0%, #625EDC 100%);
    background-clip: text;
}

.personal_activity_border {
    position: absolute;
    width: 2px;
    height: 100%;
    right: 0;
    top: 0;
}

.personal_activity_count, .personal_activity_name {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}

@media only screen and (max-width: 1355px) {
    .personal_activity {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 1295px) {
    .personal_school_name {
        font-size: 12px;
        line-height: 14px;
    }

    .personal_message {
        width: 40px;
        height: 40px;
    }

    .personal_name {
        font-size: 18px;
        line-height: 26px;
    }

    .personal_education_text, .personal_email_text {
        font-size: 12px;
    }

    .personal_activity {
        padding: 0 10px;
    }

    .personal_activity_count, .personal_activity_name {
        font-size: 16px;
        line-height: 20px;
    }
}
