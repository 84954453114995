.comment_reply_form_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}
.comment_reply_form_container.for_teacher{
    flex-direction: column;
}

.user_avatar {
    border-radius: 50%;
    outline: 1px solid #c0bebe;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    overflow: hidden;
}

.user_avatar_img {
    width: 100%;
    height: 100%;
}

.comment_reply_form {
    position: relative;
    width: calc(100% - 40px);
    background: rgb(100, 100, 100);
    border-radius: 4px;
}

.comment_reply_form_container.for_teacher .comment_reply_form{
    width: 100%;
    margin-top: 17px;
}

.comment_reply_input {
    height: 100%;
    position: relative;
    width: 100%;
    background: rgba(100, 100, 100, 0.57);
    border-radius: 4px;
    padding: 12px 43px 12px 12px;
    color: #FFFFFF;
}

.comment_reply_input:focus {
    outline: none !important;
    border: .5px solid #FFFFFF !important;
}

.comment_reply_input::placeholder {
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);

}

.comment_reply_btn {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 32px;
    height: 32px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.comment_reply_btn.submit_disabled{
    cursor: default;
}
