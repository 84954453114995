.selectedTypes {
  padding: 10px;
  /*width: 140px;*/
  /* general / fizika */
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;

  color: #ffffff;
}
.selectedTypesBody {
  position: relative;
}
.selectedTypesInput {
  font-family: "Mardoto", sans-serif;
  width: 100%;
  /*height: 100%;*/
  height: 32px;
  padding: 8.5px 10px;
  border-radius: 12px;
  color: #ffffff;
  line-height: 15px;
  font-size: 13px;
  letter-spacing: 0.01em;
}
.selectedTypesInput:focus {
  /* border: none !important; */
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.selectedTypesInputFromUsersPage {
  color: #8c8e92;
  border: 1px solid #8c8e92 !important;
  padding-right: 25px;
  cursor: pointer !important;
}

.selectedTypesInputFromUsersPage:focus {
  border: 0 0 0 2.5px #93c7ff inset !important;
}

.selectedTypesContainer {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  top: 37px;
  right: 0;
  font-family: "Mardoto", sans-serif;
  color: #6c6c6c;
  letter-spacing: 0.1em;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.selectedTypesContainer li {
  padding: 16px 17px;
  cursor: pointer;
}
.selectedTypesOpenedIcon {
  position: absolute;
  top: 13px;
  right: 13px;
}

.selectedTypesOpenedIcon:hover {
  cursor: pointer;
}

@media screen and (max-height: 970px) {
  .selectedTypesContainer {
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    height: 130px;
    z-index: 1;
  }

  .selectedTypesContainer::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  .selectedTypesContainer::-webkit-scrollbar-track {
    /* background: #bd3d3d; */
  }

  /* Handle */
  .selectedTypesContainer::-webkit-scrollbar-thumb {
    background: #888;
  }
}
