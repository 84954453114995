.background-pop-up {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.pop-up-container {
  position: fixed;
  top: 45%;
  left: 50%;
  height: 70%;
  max-width: 90%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 24px;
  z-index: 1000;
  border-radius: 15px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; */
  min-height: 182px;
  min-width: 250px;
}

.pop-up-container .pop-up-container-nav-bar {
  display: flex;
  justify-content: space-between;
}

.pop-up-container-nav-bar .pop-up-container-img-count {
  display: flex;
  align-items: center;
}

.pop-up-container .close-pop-up-icon {
  cursor: pointer;
}

.pop-up-container .pop-up-container {
  width: 100%;
  height: auto;
  background-size: cover;
}

.pop-up-container .pop-up-image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  padding: 24px 0;
}

.pop-up-lsn-plan-number {
  width: 30px;
  height: 30px;
  background-color: #ececec;
  border-radius: 50%;
  align-content: center;
  text-align: center;
  margin-right: 27px;
}

.pop-up-lsn-plan-number > span {
  font-family: "Mardotto" sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.75px;
  color: #4a4a4a;
}

.pop-up-container-img-count .arrow-container {
  margin: 0;
}

.pop-up-container-img-count .pop-up-arrows-container {
  display: flex;
  gap: 8px;
  margin-left: 17px;
}

.pop-up-container-img-count .pop-up-contaier-title {
  display: flex;
  gap: 10px;
}

.pop-up-container-img-count .pop-up-contaier-title > span {
  color: #4a4a4a;
  font-family: "Mardotto" sans-serif;
  font-weight: 500;
  font-size: 20px;
}
