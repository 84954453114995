.selectedContainer {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  top: 37px;
  font-family: "Mardoto", sans-serif;
  color: #6c6c6c;
  letter-spacing: 0.1em;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 150px;
  overflow-y: scroll;
}
.selectedContainer option,
.selectedContainer div {
  padding: 16px 17px;
  cursor: pointer;
}

.selectItemContainer {
  padding: 7.5px 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  justify-content: space-between;
}

.selectItemContainer:hover {
  background-color: #dfe3e7;
}
.select-text-about-subject {
  font-family: "Mardoto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #6c6c6c;
  width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-height: 970px) {
  .selectedContainer {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
  }
  .selectedContainer::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  .selectedContainer::-webkit-scrollbar-track {
    /* background: #bd3d3d; */
  }

  /* Handle */
  .selectedContainer::-webkit-scrollbar-thumb {
    background: #888;
  }
}
