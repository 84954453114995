.lesson-plan-row {
  display: flex;
  margin: 0 0 20px 0;
  width: 100%;
}

.lesson-plan-row-body {
  display: flex;
  border-top: 2px solid #b1bcdf;
  padding: 9px 5px 0 0;
  font-size: 16px;
}
.lesson-plan-row-comment-container {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.lesson-plan-description-title {
  min-width: 286px;
  width: 286px;
  height: 64px;
  border-radius: 32px 0 0 32px;
  background-color: #b1bcdf;
  display: flex;
  padding: 0 0 0 35px;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #000000;
  font-family: "Mardoto", sans-serif;
  flex-wrap: wrap;
}

.lesson-plan-description {
  /*width: 600px;*/
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 0 40px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-family: "Mardoto", sans-serif;
}

.lesson-plan-description span,
.lesson-plan-description p {
  width: 692px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.lesson-plan-description img {
  max-width: 710px;
}

a {
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.file-icon {
  width: 25px;
}

.descFilesDiv {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}

.descFilesDiv > div {
  display: flex;
  align-items: center;
  /*padding: 10px 0 10px 40px !important;*/
}

.descName p {
  display: inline;
  /*word-break: break-all;*/
}

.description-text {
  font-weight: 400;
}

.lesson-plan-description h1 {
  line-height: 45px;
}

.description-text img {
  margin: 10px 0;
}

@media screen and (max-width: 1160px) {
  .lesson-plan-description-title {
    min-width: 250px;
    width: 250px;
    min-width: 250px;
  }

  /*.lesson-plan-description {*/
  /*  width: 500px;*/
  /*  max-width: 450px;*/
  /*  min-width: 400px;*/
  /*}*/
  .lesson-plan-description p,
  .lesson-plan-description span {
    width: 650px;
  }
}

@media screen and (max-width: 1012px) {
  .lesson-plan-description p,
  .lesson-plan-description span {
    width: 600px;
  }
}

@media screen and (max-width: 950px) {
  .lesson-plan-description p,
  .lesson-plan-description span {
    width: 550px;
  }
}

@media screen and (max-width: 900px) {
  .lesson-plan-description p,
  .lesson-plan-description span {
    width: 450px;
  }
}
