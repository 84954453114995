.alert-button {
  color: #ffffff;
  background: linear-gradient(83.13deg, #6fd89c 0%, #46b776 100%);
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  font-family: "Mardoto", sans-serif;
  font-size: 20px;
  width: fit-content;
}

.previous-button {
  background: #EEF1F6 !important;
  color: #8C8E92 !important;
}

.alert-button-responsive {
  color: #ffffff;
  background: linear-gradient(83.13deg, #6fd89c 0%, #46b776 100%);
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  font-family: "Mardoto", sans-serif;
  font-size: 16px;
  width: fit-content;
}

.alert-button-confirm {
  color: #ffffff;
  background: linear-gradient(83.13deg, #6fd89c 0%, #46b776 100%);
  padding: 11px 15px;
  border-radius: 6px;
  text-align: center;
  font-family: "Mardoto", sans-serif;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
  margin-right: 12px;
}

.borard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.board-content {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}

.board-title {
  font-family: "Mardoto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgba(74, 74, 74, 1);
}

.board-title-responsive {
  font-family: "Mardoto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.board-title-empty {
  height: 20px;
}

.righ-part {
  margin-left: 20px;
}

.board-text {
  font-family: "Mardoto", sans-serif;
  font-size: 20px;
  line-height: 23.44px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.board-text-responsive {
  font-size: 16px;
  line-height: 23.44px;
  font-weight: 400;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 1);
}

.link-content {
  margin-bottom: 12px;
}

.board-line {
  width: 346px;
  background-color: #b1bcdf;
  height: 1px;
  margin-bottom: 8px;
}

.board-line-responsive {
  background-color: #b1bcdf;
  height: 1px;
  margin-bottom: 4px;
  margin-top: 16px;
}
.board-container {
  margin-bottom: 20px;
}

.board-image-responsive {
  width: 100%;
  max-height: 506px;
  min-height: 212px;
  object-fit: contain;
}

.raiting-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 31.2px;
}

.raiting {
  padding: 8px 0;
  margin-bottom: 16px;
}

.raiting-content {
  text-align: center;
  padding-bottom: 67px;
}

.raiting-content-responsive {
  text-align: center;
}

.available-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: #8c8e92;
  margin: 16px 0;
}

.confirm-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.star-rating {
  font-size: 24px;
  display: inline-block;
}

.raiting-star {
  cursor: pointer;
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
  padding-bottom: 200px;
}

.progress-container img {
  max-width: 320px;
  max-height: 320px;
}

.progress-container .confirm-button .alert-button-confirm-disabled {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.timer-content {
  background: linear-gradient(74.09deg, #9d9aff 0%, #625edc 100%);
  padding: 5px 10px;
  border-radius: 6px;
  color: rgba(255, 255, 255, 1);
}

.timer-img {
  margin-right: 5px;
}

.progress-bar {
  height: 8px;
  background: linear-gradient(86.71deg, #6ab1fe -1.37%, #2b89ef 100%);
  position: relative;
  border-radius: 10px;
}

.progress-wrapper {
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: rgba(242, 242, 242, 1);
  margin-bottom: 20px;
}

.progress-value {
  position: absolute;
  left: 150%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

input[type="range"] {
  width: 100%;
}

.lp-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 16px;
}

.board-responsive {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(74, 74, 74, 1);
}

.responsive-content {
  height: 100%;
}

.back-next {
  display: flex;
  justify-content: center;
  background-color: #FFF;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 70px;
}

.board-image {
  width: 484px;
  margin-bottom: 8px;
  cursor: pointer;
  object-fit: cover;
}

.board-image:hover {
  transform: scale(1.04);
  transition: 400ms;
}

.detail-image {
  margin-bottom: 16px;
  max-width: 1045px;
  max-height: 633px;
  min-width: 700px;
  object-fit: contain;
}

.board-images {
  width: 484px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.thank {
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(74, 74, 74, 1);
  margin-bottom: 67px;
}

.alert-button-confirm-disabled {
  padding: 11px 15px;
  border-radius: 6px;
  text-align: center;
  font-family: "Mardoto", sans-serif;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
  background: #eceef0 !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

.rating-popup {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: 25%;
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 1);
}

.rating-parent {
  height: 100%;
  width: 100%;
  position: relative;
}

.close-rating {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  cursor: pointer;
}

.close-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.detail-container {
  text-align: center;
}

.board-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.studentActionBoard {
  margin-left: 355px;
}

.studentAction {
  margin-left: 495px;
}

.link-student-description {
  width: 100%;
  background-color: rgba(129, 213, 165, 1);
  display: flex;
  align-items: flex-start;
  border-radius: 25px;
  color: rgba(28, 28, 28, 1);
  padding: 3px 10px 3px 3px;
  margin-bottom: 16px;
}

.student-part {
  margin-bottom: 16px;
}

.student-text {
  margin-bottom: 12px;
}

.student-desc {
  display: block;
}

.student-desc p img {
  display: block;
  margin: 10px 0;
}

.student-desc p a {
  display: block;
  margin-top: 12px;
  color: #0a66b7;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
}

.student-desc p {
  display: block;
  font-family: "Mardoto", sans-serif;
  font-size: 20px;
  line-height: 23.44px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
