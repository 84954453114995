.right-sidebar-container-from-user-profile-page {
    background: rgba(255, 255, 255, 1);
    display: flex;
    overflow-x: hidden;
    width: calc(100% - 40px);
    max-height: 100vh;
    padding: 20px 20px 5px;
    position: relative;
    flex-direction: column;
}
.right_sidebar_section_name{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1C1C1C;
    margin-bottom: 14px;
}
.right_sidebar_figure{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto 41px;
}
.right_sidebar_profile_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.right_sidebar_user_name{
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 10px;
    overflow-wrap: break-word;
}
.right_sidebar_pagination_container{
    display: flex;
    justify-content: center;
}
.right_sidebar_data_container{
    overflow: auto;
}
.right_sidebar_data_container::-webkit-scrollbar {
    width: 6px;
}
.right_sidebar_data_container::-webkit-scrollbar-thumb {
    background: #8c8e92;
    transition: all 0.4s;
    border-radius: 7px;
}
.right_sidebar_data_container::-webkit-scrollbar-thumb:hover {
    background: #77797c;
}
