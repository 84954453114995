* {
    margin: 0;
    padding: 0;
}

.comment_form_container {
    margin-bottom: 11px;
    width: 100%;
}

.comment_form {
    position: relative;
    width: 100%;
    background: rgb(100, 100, 100);
    margin-top: 17px;
    border-radius: 4px;
}

.comment_form_input {
    height: 100%;
    position: relative;
    width: 100%;
    background: rgba(100, 100, 100, 0.57);
    border-radius: 4px;
    padding: 12px 43px 12px 12px;
    color: #FFFFFF;
}

.comment_form_input:focus {
    outline: none !important;
    border: .5px solid #FFFFFF !important;
}


.comment_form_input::placeholder {
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.6);

}

.comment_form_btn {
    position: absolute;
    right: 4px;
    top: 5px;
    width: 32px;
    height: 32px;
    background: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
