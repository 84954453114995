.imagesInContainer {
    width: 100px;
    height: 28px;
    background: #ECECEC;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    padding-left: 8px;
    margin-top: 15px;
    color:  #4A4A4A;
    position: relative;
    cursor: pointer;
}

.imagesLength {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    width: 100%;
    text-align: center;
}

.doth {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 7px 10px 5px;
    background-color: #727272;;
}
