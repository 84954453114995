.enterbtn {
  font-size: 16px;
  width: 344px;
  height: 50px;
  border-radius: 5px;
  background: linear-gradient(86.71deg, #6ab1fe 1.37%, #2b89ef 100%);
  border: #ffffff;
  color: white;
  /* margin-top: 30px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-title {
  color: #ffffff;
}
.disabled {
  background: #ECEEF0 !important;
  color: #666666 !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}


.loading_on_btn {
  width: 20px;
  height: 20px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 40%,#ffffff 41%);
  background: linear-gradient(0deg ,rgba(255,255,255,0.5) 50%,rgba(255,255,255,1) 0) center/1.9px 100%,
  linear-gradient(90deg,rgba(255,255,255,0.25) 50%,rgba(255,255,255,0.75) 0) center/100% 1.9px;
  background-repeat: no-repeat;
  animation: loading_on_btn-d3o0rx 1s infinite steps(12);
}

.loading_on_btn::before,
.loading_on_btn::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.loading_on_btn::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes loading_on_btn-d3o0rx {
  100% {
    transform: rotate(1turn);
  }
}
