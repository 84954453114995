.aside_comment_header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  height: 30px;
}

.aside_comment_form {
  width: calc(100% - 24px);
  height: 24px;
}

.aside_comment_btn {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-color: unset;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.aside_comment_input {
  width: calc(100% - 28px);
  height: 100%;
  background-color: inherit;
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.51);
  border: 0 !important;
  outline: 0 !important;
}

.aside_comment_input:focus {
  border: 0 !important;
  outline: 0 !important;
}

.aside_comment_input::placeholder {
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.51);
}

.aside_comment_btn_icon {
  width: 100%;
  height: 100%;
}

.aside_comment_filter {
  width: 24px;
  height: 24px;
  background-color: #2b2b2b;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.aside_comment_filter_icon {
  width: 100%;
  height: 100%;
}

.aside_comment_filter_container {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  border-radius: 4px;
  background-color: #2b2b2b;
  width: 180px;
}

.aside_comment_filter_inner{
  position: relative;
  width: 100%;
  height: 100%;
}

.aside_comment_filter_item, .aside_comment_types_item {
  padding: 4px 12px;
  font-family: "Mardoto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
  background-color: unset;
  border: 0;
  outline: 0;
  width: 100%;
  display: flex;
  text-align: left;
  transition: all .4s;
  text-transform: capitalize;
  align-items: center;
  justify-content: space-between;
}
.aside_comment_filter_item.active_item, .aside_comment_types_item.active_item{
  background-color: #404040;
}
.aside_comment_filter_item:nth-child(1),
.aside_comment_types_item:nth-child(1) {
  border-radius: 4px 4px 0 0;
}
.aside_comment_filter_item:nth-last-child(1),
.aside_comment_types_item:nth-last-child(1) {
  border-radius: 0 0 4px 4px;
}
.aside_comment_filter_item:hover,
.aside_comment_types_item:hover{
  background-color: #404040;
}

.aside_comment_types{
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  border-radius: 4px;
  background-color: #2b2b2b;
  width: 180px;
  list-style-type: none;
}

.aside_comment_filter_item_icon{
  width: 10px;
}
