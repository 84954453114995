.page-count {
    height: 24px;
    width: 59px;
}

.cursor-initial {
    cursor: initial !important;
}

.cursor-initial:hover {
    background-color: transparent !important;
}

.page-numbers {
    height: 28px;
}
.styleInAllChild {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination > li {
    border-radius: 6px;
    transition: all .4s;
    width: 32px;
    text-align: center;
}

.pagination > li > a {
    font-family: 'Mardoto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #8C8E92;
    transition: all .4s;
    padding: 4px 11px;
    border-radius: 6px;
}

.pagination > li:hover {
    color: #6AB1FE;
    background: #e4f0fe;
}

.page-current {
    background-color: #e4f0fe ;
    opacity: 0.4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-current > a {
    pointer-events: none;
    /*background: #e4f0fe !important;*/
}

li.page-current > a {
    color: #2B89EF ;
}

.three-dots {
    margin-bottom: 10px;
}

.pagination-container {
    display: block;
    margin: 20px auto;
    padding: 11px 5px;
    align-content: center;
    border: 1px solid #dfe3e7;
    border-radius: 8px;
    width: 314px;
    align-self: center;
    /* justify-content: center; */
    background-color: white;
}

.three-dots::after {
    content: "...";
    width: inherit;
    margin-left: -5px;
    font-size: 16px;
    position: relative;
    color: #8c8e92;
}

.arrow-right {
    margin-right: 8px;
    margin-left: 10px;
    transform: rotate(180deg);
}

.arrow-left {
    margin-right: 10px;
    margin-left: 8px;

}

.arrow-container {
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    transition: all .4s;
    margin: 0 18px
}

.arrow {
    cursor: pointer;
}

.arrow-container:hover {
    background: RGBA(140, 142, 146, 0.1);
}

.pagination {
    display: flex;
    justify-content: center;
    padding-left: 0;
    border-radius: 4px;
    align-items: center;
}

li > a {
    color: black;
    text-decoration: none;
}

.pagination > li {
    cursor: pointer;
    margin: 0 2px;
    display: flex;
    justify-content: center;
}


@media screen and  (min-width: 1121px) and (max-width: 1280px) {
    .pagination > li > a {
        font-size: 1.2vw;
    }

    .pagination > li {
        margin: 0 0.1vw;
    }

    .arrow {
        width: 1vw;
    }

    /*.pagination-container {*/
    /*    width: 60%;*/
    /*}*/
}
