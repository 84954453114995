.select_container {
  margin: 20px 0 0 0;
}
.select-label {
  font-size: 16px;
}
.requiredField {
  width: 344px;
  height: 50px;
  border-radius: 5px;
  border: 1.5px solid #ea6670;
  /* background: white; */
  /* display: inline-block; */
  margin-top: 7px;
  font-size: 15px;
  /* margin-bottom: 19px; */
  padding: 16px;
  line-height: 18px;
}

.select_input_component {
  width: 344px;
  height: 50px;
  border-radius: 5px;
  border: 1.5px solid #93c7ff;
  margin-top: 7px;
  font-size: 15px;
  padding: 16px;
  line-height: 18px;
  display: -webkit-box;
}

.select {
  position: absolute;
  background: #fff;
  /* width: 344px !important; */
  width: 100%;
  box-shadow: 0px 0px 10px -4px #757575;
  z-index: 2;
  max-height: 257px;
  overflow-y: auto;
}
.select li {
  min-height: 40px;
  max-height: 69px;
  padding: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 3;
          line-clamp: 3;
  -webkit-box-orient: vertical;
}
.select li:hover {
  background: #f7f7f7;
}
