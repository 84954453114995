.lesson-plan-div {
  overflow-wrap: break-word;
  position: relative;
  padding: 25px 30px 0 25px;
  font-size: 25px;
}
.lesson-plan-div a {
  color: #0a66b7;
}
.lesson-plan-div ul,
.lesson-plan-div ol {
  margin-left: 20px;
}
.logo {
  position: absolute;
  top: 12px;
  right: 10px;
}

.lsn-plan-details {
}

.show-lesspn-plan-title {
  font-weight: bold;
  font-size: 22px;
  padding: 40px 16px;
  line-height: 26px;
}

.btn-chat {
  width: 54px;
  height: 24px;
  padding: 4px 4px 4px 6px;
  display: flex;
  background: linear-gradient(
    86.71deg,
    rgba(106, 177, 254, 0.2) -1.37%,
    rgba(43, 137, 239, 0.2) 100%
  );
  border-radius: 4px;
}

.sections {
  background-color: #f7f8fc;
  border-top: 1px solid #dfe3e7;
  border-bottom: 1px solid #dfe3e7;
}

.show-lesson-plan-section-title {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px 12px 16px;
}

.section-action {
  min-height: 155px;

  padding: 20px 16px;
}

.show-lesson-plan-section-text {
  font-size: 16px;
  line-height: 25px;
  overflow-wrap: break-word;
}

.textarea-comment {
  width: 480px;
  /* height: 36px; */
  background: #f7f8fc;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border: 1.5px solid #dfe3e7;
  box-sizing: border-box;
  border-radius: 60px;
  padding: 9px 5px 5px 37px;
  overflow-y: hidden;
}

.show-lesson-plan-comment-icon {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.com {
  line-height: 16px;
  color: #4a4a4a;
}

.all-comments {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: auto;
}

.send {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.pointer {
  width: 5px;
  height: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 50px;
}

.user-name {
  color: #3a4e8a;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.see-all {
  color: rgba(108, 108, 108, 0.7);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-left: 13px;
  cursor: pointer;
}

@media screen and (max-width: 1120px){
  .lesson-plan-div {
    padding: 0px 30px !important;

  }
  .responsive-lsn-plan-items {
    border-top: 1px solid #DFE3E7;
    width: 100%;
    padding-top: 20px;
  }
}
